import React, {useState, useEffect} from "react";

import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components'
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import { MenuItem } from '@material-ui/core';


import Link from "../components/Link";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import RightContainer from "../components/RightContainer";
import MUIDrawer from "../components/Drawer";
import {MenuContext} from '../components/PostUtils';

import theme from "../theme.js";

export const query = graphql`
query SITE_INDEX_QUERY {
  site {
    siteMetadata {
      title
      description
      url
    }
  }
  allMarkdownRemark(
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {ispublished: {nin: false}, ispage: {nin: true}}}
  ) {
    nodes {
      id
      excerpt(pruneLength: 250)
      frontmatter {
        title
        date
        slug
        tags
      }
    }
  }
}
`;


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
  },
  container: {
    flexGrow: 1,
  },
  grid: {
    height: "100vh",
  },

  topgrid: {
    marginTop: "10px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  drawerPaper: {
    width: '100%',
  },
}));

const ContainerDiv = styled.div`
  flex-grow: 1;
  padding-left: 35px;
  padding-right: 35px;
  min-height: 100vh;
  ${props => props.theme.breakpoints.between("sm", "xl")} {
    background: 
      linear-gradient(#4c494f, #4c494f) bottom left/ 50% 25px,
      linear-gradient(#e1b5b3, #e1b5b3) top right / 50% 25px,
      linear-gradient(#4c494f, #4c494f) top left/ 25px 50%,
      linear-gradient(#e1b5b3, #e1b5b3) bottom right / 25px 50%, #fff;
    background-repeat: no-repeat;
    padding-left: 20px;
    padding-right: 20px;

  }
  ${props => props.theme.breakpoints.down("xs")} {
    border-top: 25px solid  #e1b5b3;
    border-bottom: 25px solid #4c494f;
    padding-left: 0px;
    padding-right: 0px;
  }

`;

const PostContainer = styled.div`
  /*background-color: lightyellow;*/
  margin-right: auto;
  margin-left: 2rem;
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: right;
  /*border: 1px solid black;*/
  /*overflow: scroll;*/
  margin-bottom: 4rem;
 
  ${props => props.theme.breakpoints.down("xs")} {
    margin-top: 0rem; 
    margin-right: 0rem;
    margin-left: 0rem;
    min-height: auto;
    min-width: auto;
  }
`;

const IndexPostListItem = styled(ListItem)`
 
  & .myprimary {
    color: red;
    font-family: Old Standard TT, serif;
    font-weight: bold;
    font-size: 2rem;
    color: #4c494f;

  }  
  
  & p.mysecondary {
    font-family: Lora,sans serif;
  }

  &:hover {
    text-decoration: none;
  }
 
`;

const IndexPostListItemText = styled(ListItemText)`

  font-family: Old Standard TT, serif;
  font-size: 2rem;
  font-weight: bold;

  ${props => props.theme.breakpoints.down("xs")} {
    font-size: 2.2em;
    font-weight: bold;
  }

`;

const IndexPostDate = styled.div`
  display: flex;
  flex-direction: 1;
  color: rgb(0,0,0,0.3);
  font-family: Open Sans, non serif;
`;

const IndexStyledPostLink = styled(Link)`
  font-family: Old Standard TT, serif;
  font-weight: bold;
  color: #4c494f;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1em;
  -webkit-font-smoothing: subpixel-antialiased !important; 
  text-rendering:optimizeLegibility !important;
  ${props => props.theme.breakpoints.down("xs")} {
    letter-spacing: -0.1rem;
  }  
`;


export default function Index({ data }) {
  const classes = useStyles(theme);
  // Keep the burger menu closed when it first starts
  const [isOpen, setIsOpen] = useState(false);
  return (

    <Layout>
      <MenuContext.Provider value={{isOpen: isOpen, setIsOpen: setIsOpen}}>
        <Hidden smUp>
          <MUIDrawer/>
        </Hidden>
        <Seo
          title={data.site.siteMetadata.title}
          description={data.site.siteMetadata.description}
    /*image={post.frontmatter.image.childImageSharp.sizes.src}*/
          pathname={data.site.siteMetadata.url}
          article
        />
        <ThemeProvider theme={theme}>
          <ContainerDiv>
            <Grid container spacing={0}>
              <Hidden smUp>
                <Grid item xs={12}>
                  <RightContainer/>
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                <PostContainer>
                  <List dense={true}>
                    {
                      data.allMarkdownRemark.nodes.map(({ excerpt, frontmatter }) => {
                        return (
                          <IndexPostListItem
                            key={frontmatter.slug}
                            component={IndexStyledPostLink}
                            to={frontmatter.slug}
                          >
                            <IndexPostDate>
                              {frontmatter.date}
                            </IndexPostDate>
                            <IndexPostListItemText
                              disableTypography
                              primary={frontmatter.title}
                              classes={{
                                primary: 'myprimary',
                              }}
                            />
                          </IndexPostListItem>
                        );
                      })
                    }
                  </List>
                </PostContainer>
              </Grid>
              <Hidden xsDown>
                <Grid item sm={2} md={2} lg={2} xl={2}>
                  <RightContainer/>
                </Grid>
              </Hidden>
            </Grid>
          </ContainerDiv>
        </ThemeProvider>
      </MenuContext.Provider>
    </Layout>
  );
}
